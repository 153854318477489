




























import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile",
      language: "language",
      isCN: "isCN"
    })
  },
  methods: {
    onOpen(url: string) {
      window.open(url, "_blank");
    }
  }
});
